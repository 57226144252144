/* LinkExpired.css */
.link-expired-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
}

.link-expired-content {
  text-align: center;
  color: white; /* Text color */
  animation: fadeIn 2s ease-in-out; /* Animation for fade-in effect */
}

.link-expired-icon {
  margin-bottom: 20px;
}

.link-expired-heading {
  font-size: 2.5rem;
  color: #ffffff; /* White color for heading */
  margin-bottom: 10px;
}

.link-expired-message {
  font-size: 1.2rem;
  color: #ffffff; /* White color for message */
  line-height: 1.6;
}

/* Animation keyframes */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Responsive styling */
@media (max-width: 768px) {
  .link-expired-heading {
    font-size: 2rem;
  }
  
  .link-expired-message {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .link-expired-heading {
    font-size: 1.5rem;
  }
  
  .link-expired-message {
    font-size: 0.9rem;
  }
}
