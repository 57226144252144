/* InterviewNotStarted.css */
.interview-not-started-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    padding: 20px;
    color: white;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .interview-not-started-icon {
    font-size: 5rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .interview-not-started-title {
    font-size: 3rem;
    margin: 0;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    animation: fadeIn 2s ease-in-out;
  }
  
  .interview-not-started-message {
    font-size: 1.5rem;
    color: #f1f1f1;
    margin: 10px 0 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2.5s ease-in-out;
  }
  
  .interview-not-started-countdown {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #ff6347;
    animation: fadeIn 1s ease-in-out;
  }
  
  .interview-not-started-close-message {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #ffffff;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .interview-not-started-title {
      font-size: 2.5rem;
    }
  
    .interview-not-started-message {
      font-size: 1.2rem;
    }
  
    .interview-not-started-icon {
      font-size: 4rem;
    }
  }
  
  @media (max-width: 480px) {
    .interview-not-started-title {
      font-size: 2rem;
    }
  
    .interview-not-started-message {
      font-size: 1rem;
    }
  
    .interview-not-started-countdown {
      font-size: 1rem;
    }
  
    .interview-not-started-icon {
      font-size: 3rem;
    }
  }
  
  /* Keyframes for animation */
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  