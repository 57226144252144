
  .heading{
    text-align: center;
    font-size: 30px;
  }
  .prescreen-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 30px;
   margin-top: 70px;
    background:linear-gradient(to right, #bde0ff 0%, #00f3ff75 100%);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    font-size: larger;
    font-weight: bolder;
    margin-bottom:30px;

  }
  .buttonNext{
    color:#007bff;
    height:50px;
    width:200px;
    border-radius: 8px;
    font-weight: bold;
   
  }
  h2 {
    text-align: center;
    color: #333;
  }
  
  .prescreen-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .prescreen-options {
    display: flex;
    gap: 10px;
  }
  
  label {
    color: #333;
  }
  
  input[type="radio"] {
    transform: scale(1.2);
  }
  
  .concernscreen-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .concernscreen-button:hover {
    background-color: #0056b3;
  }

  .stepper-container{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-left: 9%;
  }

  .stepper-card{
     height: 10vh;
     padding-top: 1%;
     padding-bottom: 5%;
     padding-right: 10%;
     padding-left: 10%;
     box-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);
     background:linear-gradient(to right, #bde0ff 0%, #00f3ff75 100%);
     border-radius: 6px;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
     /* padding: 20px; */
  }
  .timer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    padding-top:30px ;
  }
  .timer{
    background:linear-gradient(to right, #bde0ff 0%, #00f3ff75 100%);
    height:50px;
    width:105px;
    padding: 8px;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.5);
    margin-right: auto;
  }

  .main-container{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  @media (max-width: 600px) {
    .prescreen-question {
      flex-direction: column;
      align-items: flex-start;
    }
  }
