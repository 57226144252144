
/* Add a wrapper to center the container */
.equipment-check-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  /* Center the equipment-check-container within the wrapper */
  .equipment-check-container {
    text-align: center;
    padding: 20px;
    max-width: 1000px;
    background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    width: 900px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  .check-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .check-item {
    border: 1px solid #ddd;
    padding: 20px;
    width: 140px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .check-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .check-item p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .next-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .next-button:hover {
    background-color: #218838;
  }
  
  .next-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .check-grid {
        flex-direction: column;
        gap: 15px;
    }
  
    .check-item {
        width: 100%;
        max-width: 320px;
        height: auto;
        padding: 15px;
    }
  
    .check-item p {
        font-size: 16px;
    }
  
    .next-button {
        width: 100%;
        padding: 15px;
        font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .check-grid {
        gap: 10px;
    }
  
    .check-item {
        width: 100%;
        max-width: 300px;
        padding: 10px;
    }
  
    .check-item p {
        font-size: 14px;
    }
  
    .next-button {
        font-size: 16px;
        padding: 12px;
    }
  }
  