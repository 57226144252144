
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.app {
  position: relative;
  overflow: hidden;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.top-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid lightgrey;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding-left: 7px;
  padding-right: 7px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loading-text {
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.logo {
  height:50px;
  width: 110px;
  padding-left: 10px;
}

.user-class{
  padding-left: 5px;
}
.user-profile {
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 5px;
}
 
.user-profile img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.icon-user{
  background-color: rgb(200, 203, 204);
  border-radius: 50px;
  height: 40px;
  width: 40px;
  align-items: center;
  align-content: center;
  text-align: center;
  margin:5px;
}
 
.web-avatar-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: auto;
  background-color: white;
  margin-top: 1%;
  margin-bottom: 1%;
}
 
.Webcam-container {
  width: 50vw;
  height: 70vh;
  background-color: rgb(45, 38, 38); 
  margin: 10px;
  border-radius: 8px;
}
 
.avatar-container {
  width: 50vw;
  height: 70vh;
  background-color: rgb(45, 38, 38);
  margin: 10px;
  border-radius: 8px;
  padding:10px;
}
 
 
.custom1 {
  height: 100px;
  background-color: white;
  padding: 10px;
  font-size: 25px;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 
.timer-bg{
    background-color: #f0f0f0;
    border-radius: 7px;
    width:150px;
    align-content: center;
    padding-left: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
}
 
.red-circle{
  font-size: 17px;
  color:red;
  margin-top: 20px;
}
.timer-font{
  color: black;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}
 
.custom2{
  height: 100px;
  align-items: center;
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px;
}
 
.left-arrow{
  background-color: #f0f0f0;
  border-radius: 7px;
  height:60px;
  width: 60px;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}
.right-arrow{
  background-color: #f0f0f0;
  border-radius: 7px;
  height:60px;
  width: 60px;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}
.question-number{
  background-color: #f0f0f0;
  border-radius: 7px;
  height:60px;
  width: 190px;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 700;
  padding-top: 12px;
}
.custom3{
  height: 100px;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  padding: 10px;
 
}
 
.button-danger {
  background-color:red; 
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  height:60px;
  font-weight: 600;
  width:250px;
  font-size: 18px;
  margin: 4px 12px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  margin-left: 30px;
}
 
.button-success {
  border: none;
  border-radius: 5px;
  padding: 5px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  height: 60px;
  width: 250px;
  font-size: 18px;
  margin: 4px 12px;
  margin-right: 30px;
  cursor: pointer;
  transition-duration: 0.4s;
  font-weight: 600;
}
.button-container {
  align-items: center !important;
  justify-content: center;
}
 
 
.text-container{
  width: 100%;
  height:15%;
  border-radius: 8px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.516); 
  backdrop-filter: blur(10px); 
  padding: 10px;
  font-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
 
}
 
.textarea-avatar {
  width: 90%;
  border-radius: 8px;
  z-index: 2;
  color: #ffffff;
  background-color: transparent; 
  border: none;
  font-size: 25px;
}
 
.bar-icon{
  width:10%;
  border-radius: 8px;
  z-index: 2;
  background-color: rgba(179, 174, 174, 0.464); 
  backdrop-filter: blur(10px);
  border: none;
  font-size: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-content: center;
  text-align: center;
  color:white;
  margin-right: 10px;
}
 
.app.recording-started {
  background-color: rgba(144, 238, 144, 0.5); 
}
 
@media (max-width: 768px) {
  .web-avatar-conatiner {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .Webcam-container{
   width:100%;
   height:40%;
   margin: 5px;
  }
}
@media (max-width: 768px) {
  .avatar-container{
    width:100vw;
    height:40vh;
    margin: 5px;
  }
}
@media (max-width:768){

}
 
.network-status {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 1000;
}

.spinner {
  margin: 10px auto;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
