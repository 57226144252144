
.paragraph-editor-container {
    display: flex;
   flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 20px auto;
}

.countdown-timer {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.countdown-timer h2 {
    font-size: 1.5rem;
    color: #333;
}

.paragraph-preview h2,
.paragraph-input h2 {
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.paragraph-preview p {
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    max-height: 800px; /* Set a maximum height */
    overflow-y: auto; /* Add vertical scrollbar when content exceeds height */
}


.paragraph-input textarea {
    width: 100%;
    min-height: 200px; /* Set a minimum height */
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none; /* Allow vertical resizing */
}

.paragraph-input textarea:focus {
    border-color: #007bff;
    outline: none;
}

.word-count {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #666;
}

.word-count-alert {
    margin-top: 5px;
    color: red;
}

.paragraph-submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.paragraph-submit-button {
    width: 50%;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.paragraph-submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.paragraph-submit-button:hover:not(:disabled) {
    background-color: #0056b3;
}


@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .cards {
        width: 100%;
        margin-bottom: 20px;
    }

    .paragraph-submit-button-container {
        justify-content: center;
    }

    .paragraph-submit-button {
        width: 80%;
    }
}

.cards-container {
    gap: 10px !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cards {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 800px; /* Set a maximum height */
}

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .cards {
        width: 100%;
        margin-bottom: 20px;
    }
}
