/* concern.css */

.concernscreen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding: 20px;
  color: white;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.concernscreen-icon {
  font-size: 5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.concernscreen-title {
  font-size: 3rem;
  margin: 0;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  animation: fadeIn 2s ease-in-out;
}

.concernscreen-flex-container {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  height: 100%;
}

.concernscreen-terms-card {
  background-color: #ffffff;
  padding: 70px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.3);
  max-width: 1000px;
  max-height: 600px;
  text-align: justify;
  margin:20px;
  overflow-y: scroll;
}

.concernscreen-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 600px;
  /* max-width: 700px; */
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.3);
  margin:20px;
  padding-top: 80px;
}

.concernscreen-terms-card h2 {
  margin-top: 0;
  color: #333333;
}

.concernscreen-terms-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555555;
  padding:15px;
}

.concernscreen-checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-right: auto;
}

.concernscreen-checkbox {
  margin-right: 10px;
  height: 18px;
  width: 18px;
}

.concernscreen-checkbox-label {
  font-size: 1rem;
  color: #333333;
}

.concernscreen-checkbox-label2 {
  font-size: 1rem;
  color: #333333;
  margin-top: 20px;
}

.concernscreen-checkbox-container2{
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-right: auto;
}

.concernscreen-checkbox2 {
  margin-right: 20px;
  height: 25px;
  width: 25px;
}

.concernscreen-input {
  width: 100%;
  padding: 15px;
  margin: 7px 0;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size:13px;
}

.concernscreen-button {
  display: block;
  width: 50% !important; 
  padding: 15px !important;
  background: #1799c6ad !important;
  box-shadow: 0 6px 8px 4px rgba(241, 236, 236, 0.3);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px !important;
}
.concernscreen-button:hover {
  background-color: #4facfe;
}

.concernscreen-close-message {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #ffffff;
  animation: fadeIn 1s ease-in-out;
}
.concernscreen-terms-heading{
  font-weight: bold;
  margin: 60px 35px;
}

.concernscreen-terms-heading2{
  font-weight: bold;
  margin:15px;
}

.Form-heading{
  color: white;
  font-weight: bold;
  font-size: 25px;
  margin: 20px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
}

p {
  margin: 10px 0;
}

ul {
  list-style: none;
  padding: 0;
  color: #333;
}

ul li {
  margin: 10px 0;
  margin-left:50px;
  list-style-type: disc;
  color: black;
}

ul li::before {

  font-weight: bold;
  display: inline-block;
  width: 25px;
  margin-left: -1em;
}

.highlight {
  font-weight: bold;
  color: #007BFF;
}

.note {
  font-style: italic;
}

.popup-card {
  background: #fff;
  padding:50px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  max-height:600px;
  text-align: left;
  max-width: 1000px;
}

.close-btn-container{
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.close-btn {
  background: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  height: 60px;
  width: 150px;
}

@media (max-width: 1024px) {
  .concernscreen-flex-container {
    flex-direction: row;
    align-items: center;
  }

  .concernscreen-terms-card{
    width: 60%;
    margin-right: 0;
    height: 100%;
  }
  
  .concernscreen-form {
    width: 40%;
    margin-right: 0;
  }
  .concernscreen-button {
    padding: 8px !important;
    font-size: 16px;
    font-weight: bold;
    margin-top: 0px;
  }
  .Form-heading{
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin: 5px;
  }
  .popup-card {
    padding:30px;
    max-width: 800px;
    max-height:600px;
    text-align: left;
  }
   ul li {
    font-size: 13px;
  }
  .concernscreen-terms-card p {
    font-size: 13px;
    padding: 3px;
  }
  .concernscreen-terms-heading{
    font-weight: bold;
    margin:20px;
    margin-bottom: 40px;
    font-size: 16px;
}
.concernscreen-input {
  padding: 12px;
  margin: 9px 0;
  font-size: 13px;
}
.concernscreen-checkbox-label {
  font-size: 13px;
  color: #333333;
}
.concernscreen-checkbox-label2 {
  font-size: 13px;
}
.Form-heading{
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.concernscreen-button {
    padding: 12px !important;
    font-size: 13px !important;
    font-weight: bold;
    margin-top: 10px;
}
.close-btn {
  font-size: 13px;
}
}

@media (max-width: 768px) {
  .concernscreen-flex-container {
    flex-direction: column;
    align-items: center;
    height:200%;
    margin-bottom: 10px;
  }
  .concernscreen-terms-card p{
    padding: 0px;

  }
  .concernscreen-terms-card{
    width: 80%;
    margin-right: 0;
    height: 50%;
    padding:30px;
  }
  
  .concernscreen-form {
    width: 80%;
    margin-right: 0;
    padding: 25px;
  }
  .concernscreen-button {
    padding: 12px !important;
    font-size: 14px !important;
    font-weight: bold;
    margin-top: 10px;
  }
  .Form-heading{
    font-size: 18px;
    margin:20px;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .concernscreen-container {
    height: 200vh;
    width: 100%;
    text-align: center;
    padding: 20px;
    color: white;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  .popup-card {
    padding:30px;
    max-width: 700px;
    max-height:600px;
    text-align: left;
  }
  ul li {
    font-size: 13px;
  }
  .concernscreen-terms-card p {
    font-size: 13px;
  }
  .concernscreen-checkbox-label2 {
    font-size: 13px;
  }
  .close-btn {
    font-size: 13px;
  }
}
