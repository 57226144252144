/* MeetingEnd.css */
.meeting-end-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
}

.meeting-end-content {
  text-align: center;
  color: white; /* Text color */
  animation: fadeIn 2s ease-in-out; /* Animation for fade-in effect */
}

.meeting-icon-container {
  margin-bottom: 20px;
}

.meeting-icon {
  font-size: 5rem;
  color: #ffffff; /* White color for icon */
}

.meeting-end-title {
  font-size: 2.5rem;
  color: #ffffff; /* White color for heading */
  margin-bottom: 10px;
}

.meeting-end-text {
  font-size: 1.2rem;
  color: #ffffff; /* White color for text */
  line-height: 1.6;
}

.star-rating {
  margin-top: 20px;
}

.star-icon {
  font-size: 2rem;
  margin-right: 10px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.star-icon:hover {
  color: gold; /* Color change on hover */
}

.star-icon:active {
  transform: scale(1.2); /* Scale effect on click */
}

/* Animation keyframes */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Responsive styling */
@media (max-width: 768px) {
  .meeting-end-title {
    font-size: 2rem;
  }
  
  .meeting-end-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .meeting-end-title {
    font-size: 1.5rem;
  }
  
  .meeting-end-text {
    font-size: 0.9rem;
  }
}
