.card-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-around; 
  align-items: flex-start;
  height: max-content; 
}

.card {
  margin-top: 55px;
  width: 45%; 
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: max-content; 
}

.conversation-card {
  height: 610px; 
  overflow-y: auto; 
}

.answer-card {
  width: 45%;
  height:610px; 
}

.card-body {
  text-align: left; 
  overflow-y: auto;
}

.card-title {
  text-align: center;
  font-size: 1.25rem; 
  font-weight: bold;
}

.card-text {
  font-size: 1.1rem; 
  margin-bottom: 20px; 
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  resize: none; 
  overflow-y: auto;
}


.btn {
  padding: 10px 20px;
  font-size: 1rem; 
}
